export const CONFIG = {
  CLIENT_ENDPOINT: `http://${window.location.host}`,
  API_ENDPOINT:
    process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.btvtownad.net' : 'https://tapi.btvtownad.net',
  STAGE_API_ENDPOINT: 'https://tapi.btvtownad.net',
  VPLATE_API_ENDPOINT: 'https://skbapi.vplate.io',
  LOCAL_API_ENDPOINT: 'http://localhost:3002',
  PASS_ENDPOINT: 'https://nice.checkplus.co.kr',
  KAKAO_ENDPOINT: 'https://kauth.kakao.com',
  NAVER_ENDPOINT: 'https://nid.naver.com',
  GOOGLE_ENDPOINT: 'https://accounts.google.com',
  AI_MEDIA_URL: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://media.btvtownad.net' : 'https://media-dev.skt-ai-ad.com',
  NOTICE_CATEGORIES: {
    all: '전체',
    notice: '공지/이벤트',
    service: '서비스',
    operate: '운영',
    etc: '기타',
  },
  FAQ_CATEGORIES: {
    best: '질문 BEST',
    account: '계정',
    payment: '결제',
    adReview: '광고제작 및 심의',
    adService: '광고 서비스',
    reporting: '광고 효과 및 리포팅',
  },
  TMAP_APP_KEY: process.env.REACT_APP_TMAP_APP_KEY,
  GALLERIES_COUNT: 16,
  TEMPLATE_COUNT_LIMIT: 30,
  DAYS_PER_MONTH: 30,
  CHANNEL_CONFIG: {
    pluginKey: process.env.REACT_APP_CHANNEL_CONFIG,
    profile: {
      name: 'YOUR_USER_NAME', // fill with user name
      mobileNumber: 'YOUR_USER_MOBILE_NUMBER', // fill with user phone number
      CUSTOM_VALUE_1: 'VALUE_1', // any other custom meta data
      CUSTOM_VALUE_2: 'VALUE_2',
    },
  },
  memberTermURL: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/B_tv_member_term.pdf',
  personalInfoTermURL: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/B_tv_service_personal_information_term.pdf',
  personalInfoTermURLConsult: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/btv_phone_privacy_agreement.pdf',
  terms2URL: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/B_tv_service_term.pdf',
  privacyPtatementURLMobile: 'https://m.skbroadband.com/Page.do?menu_id=F02000000',
  privacyPtatementURL: 'https://www.skbroadband.com/footer/page.do?menu_id=F01030000',
  marketingPrivacyTermUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/B_tv_service_marketing_info1.pdf',
  marketingSubscribeTermUrl: 'https://skb-public.s3.ap-northeast-2.amazonaws.com/B_tv_service_marketing_info2.pdf',
}
//
export const SKPAY_REDIRECT_URL = ((config) => {
  const url = new URL('/invoice/payment', config.API_ENDPOINT)
  url.protocol = 'https'
  return url
})(CONFIG)
//
export const PASS_AUTH_URL = ((config) => new URL('CheckPlusSafeModel/checkplus.cb', config.PASS_ENDPOINT))(CONFIG)
//
export const KAKAO_AUTH_URL = ((config) => {
  const url = new URL('/oauth/authorize', config.KAKAO_ENDPOINT)
  url.search = new URLSearchParams({
    response_type: 'code',
    client_id: '96dbd9808f94b6c7e6fcdda50d77081d',
    redirect_uri: `${config.API_ENDPOINT}/user/kakao/callback`,
  })
  return url
})(CONFIG)
//
export const NAVER_AUTH_URL = ((config) => {
  const url = new URL('/oauth2.0/authorize', config.NAVER_ENDPOINT)
  url.search = new URLSearchParams({
    response_type: 'code',
    client_id: 'PN79eQWOA2UPuvSgTePc',
    redirect_uri: `${config.API_ENDPOINT}/user/naver/callback`,
  })
  return url
})(CONFIG)
//
export const GOOGLE_AUTH_URL = ((config) => {
  const url = new URL('/o/oauth2/v2/auth/oauthchooseaccount', config.GOOGLE_ENDPOINT)
  url.search = new URLSearchParams({
    response_type: 'code',
    // access_type: "online",
    scope: [
      'https://www.googleapis.com/auth/contacts.readonly',
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ].join(' '),
    client_id: '41930653374-etubm177ad6564c3ptk7sqd8k151iqsd.apps.googleusercontent.com',
    redirect_uri: `${config.API_ENDPOINT}/user/google/callback`,
  })
  return url
})(CONFIG)
//
export const TRIAL_TOKEN = {
  accessToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0MzhmY2UxYzhjYTdiZmFiMmYyZDA1OCIsInVzZXJUeXBlIjoiMCIsImlhdCI6MTY4MTc4NDc1NCwiZXhwIjoxNzY4MTg0NzU0fQ.RCGAkVeKC0423Xm9bRSOt2GpvGlr9nl97ZEW5EuBGQM',
}
