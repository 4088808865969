import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import qs from 'query-string'

import { getProgress } from '../../services/api'
import { hideFab } from '../../services/fab'
import AppContext from '../../services/context'
import { CONFIG } from '../../services/config'
//
const AIBuild = () => {
  const { appState } = React.useContext(AppContext)
  const location = useLocation()
  const history = useHistory()
  const iframeRef = React.useRef()
  const [cookies] = useCookies(['jwt_token'])
  hideFab()
  //
  React.useEffect(() => {
    if (!iframeRef.current) {
      return
    }
    iframeRef.current.src = CONFIG.AI_MEDIA_URL
  }, [])
  //
  // React.useEffect(() => {
  //   const form = document.createElement('form')
  //   form.target = iframeRef.current.name
  //   form.method = 'POST'
  //   form.action = 'https://media-dev.skt-ai-ad.com'

  //   const fields = {
  //     assetId: location.state.assetId,
  //     userName: location.state.userName,
  //     storeName: location.state.storeName,
  //     storeAddress: location.state.storeAddress,
  //     storeTel: location.state.storeTel,
  //     storeSector: location.state.storeSector,
  //   }

  //   Object.keys(fields).forEach((key) => {
  //     const input = document.createElement('input')
  //     input.type = 'hidden'
  //     input.name = key
  //     input.value = fields[key]
  //     form.appendChild(input)
  //   })

  //   document.body.appendChild(form)
  //   form.submit()
  //   document.body.removeChild(form)

  //   return () => {
  //     // Cleanup
  //   }
  // }, [cookies.jwt_token, location.state])
  // React.useEffect(() => {
  //   const eventListener = (e) => {
  //     if (e.data?.projectId) {
  //       getProgress(cookies.jwt_token, e.data?.projectId).then((result) => {
  //         if (typeof result.assetStatus !== 'number') {
  //           return
  //         }
  //         if (result.assetStatus === 0) {
  //           history.goBack()
  //         } else {
  //           history.push({
  //             pathname: '/portal/media/result',
  //             state: {
  //               assetId: e.data?.projectId,
  //             },
  //           })
  //         }
  //       })
  //     }
  //   }
  //   window.addEventListener('message', eventListener)
  //   const baseUrl = 'https://media-dev.skt-ai-ad.com'
  //   const query = qs.stringify({
  //     assetId: location.state.assetId,
  //     userName: location.state.userName,
  //     storeName: location.state.storeName,
  //     storeAddress: location.state.storeAddress,
  //     storeTel: location.state.storeTel,
  //     storeSector: location.state.storeSector,
  //   })

  //   const url = `${baseUrl}/build?${query}`
  //   iframeRef.current.src = url
  //   return () => {
  //     window.removeEventListener('message', eventListener)
  //   }
  // }, [cookies.jwt_token, location.state, history])
  //
  return (
    <iframe
      ref={iframeRef}
      title="build"
      className="h-full w-full flex-grow"
      src={CONFIG.AI_MEDIA_URL}
      onLoad={() => {
        const message = {
          assetId: location.state.assetId,
          userName: location.state.userName || appState.userInfo?.userName,
          secretKey: 'temp_btv', // TODO: 변경 필요합니다.
          storeName: location.state.storeName,
          storeAddress: location.state.storeAddress,
          storeTel: location.state.storeTel,
          storeSector: location.state.storeSector,
        }

        iframeRef.current.contentWindow.postMessage(message, CONFIG.AI_MEDIA_URL)
      }}
    />
  )
}

export default AIBuild
