import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useLocation } from 'react-router'
//
import * as Wrappers from './wrappers'
import MyPage from '../pages/my_page/mypage'
import Account from '../pages/my_page/account'
import Point from '../pages/my_page/point'
import Promotions from '../pages/my_page/promotions'
import Coupon from '../pages/my_page/coupon'
import Settings from '../pages/my_page/settings'
import ChangePassword from '../pages/my_page/changePassword'
import Invoice from '../pages/my_page/invoice'
import BusinessManagement from '../pages/my_page/business_management'
import FranchiseManagement from '../pages/my_page/franchise_management'
//
import SignIn from '../pages/auth/signIn'
import SignUpMethod from '../pages/auth/signUpMethod'
import SignUpType from '../pages/auth/signUpType'
import SignUpResult from '../pages/auth/signUpResult'
import SignUpForm from '../pages/auth/signUpForm'
import RecoverID from '../pages/auth/recoverID'
import RecoverPwd from '../pages/auth/recoverPwd'
import home from '../pages/home/home'
import Page404 from '../pages/errors/404'
import FAQs from '../pages/customer_service/FAQs'
import Notices from '../pages/customer_service/notices'
import NoticePage from '../pages/customer_service/noticePage'
import Trial from '../pages/customer_service/trial'
import MyAds from '../pages/media/media'
import AdCampaign from '../pages/media/adCampaign'
import RequestedAdCampaign from '../pages/media/requestedAdCampaign'
import Promotion from '../pages/media/promotion'
import OurServices from '../pages/our_services/ourServices'
import Community from '../pages/community/community'
import CommunityDetail from '../pages/community/communityDetail'
import CommentPage from '../pages/community/commentPage'
import CommunityBoard from '../pages/community/communityBoard'
import EditPage from '../pages/community/editorPage'
import PostPage from '../pages/community/postPage'
import MakingAssets from '../pages/making_assets/makingAssets'
import BuildPage from '../pages/making_assets/buildPage'
import BuildTrialPage from '../pages/making_assets/buildTrialPage'
import Estimate from '../pages/customer_service/estimate'
import EstimateResult from '../pages/customer_service/estimateResult'
import Partner from '../pages/customer_service/partner'
import Consult from '../pages/customer_service/consult'
import SuccessStories from '../pages/success_stories/successStories'
import SuccessStoryPage from '../pages/success_stories/successStoryPage'
import EventPage from '../pages/event/eventPage'
import ResultPage from '../pages/making_assets/resultPage'
import ResultTrialPage from '../pages/making_assets/resultTrialPage'
import LoadingLayout from '../layouts/loadingLayout'
import PaymentResult from '../pages/media/paymentResult'
import Report from '../pages/reports/reportPage'
import ReportDetail from '../pages/reports/reportDetail'
import Tutorial from '../pages/tutorial/tutorial'
import TutorialDetail from '../pages/tutorial/tutorialDetail'
import AddVoice from '../pages/making_assets/addVoice'
import AddVoiceTrial from '../pages/making_assets/addVoiceTrial'
import RecommendPage from '../pages/media/recommend'
import SignUpFormSocial from '../pages/auth/signUpFormSocial'
import RegisterForm from '../pages/auth/registerForm'
import ExpoPage from '../pages/event/expoPage'
import FranchisePage from '../pages/franchise/franchise'
import FranchiseNotice from '../pages/franchiseNotice/notice'
import FranchiseNoticeAds from '../pages/franchiseNotice/noticeAds'
import FranchiseNoticeWrite from '../pages/franchiseNotice/noticeWrite'
import FranchiseNoticeDetail from '../pages/franchiseNotice/noticeDetail'
import FranchiseNoticeEdit from '../pages/franchiseNotice/noticeEdit'
import CableTV from '../pages/our_services/cabletv'
import InicisCallback from '../pages/media/inicisCallback'
import InicisFail from '../pages/media/inicisFail'
import AdMediaPage from '../pages/customer_service/adMedia'
import TheDreamPage from '../pages/event/thedreamPage'
import AIBuild from '../pages/making_assets/aiBuildPage'

export const Routes = [
  // portal..
  // my page..
  {
    path: ['/portal', '/portal/my-page'],
    component: MyPage,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal/my-page/account'],
    component: Account,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal', '/portal/my-page/point'],
    component: Point,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal', '/portal/my-page/promotion'],
    component: Promotions,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal', '/portal/my-page/coupon'],
    component: Coupon,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal', '/portal/my-page/settings'],
    component: Settings,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal', '/portal/my-page/change-password'],
    component: ChangePassword,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal', '/portal/my-page/payment-history'],
    component: Invoice,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal', '/portal/my-page/business-management'],
    component: BusinessManagement,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal', '/portal/my-page/franchise-management'],
    component: FranchiseManagement,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  // my ads..
  {
    path: ['/portal/media'],
    component: MyAds,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal/media/create/1/assets'],
    component: MakingAssets,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal/media/inicis/callback'],
    component: InicisCallback,
    exact: true,
    wrappers: [
      // Wrappers.LogPath,
      // Wrappers.RedirectDomain,
      // Wrappers.CollectReferrer,
      // Wrappers.OnlyAuth,
      // Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal/media/inicis/fail'],
    component: InicisFail,
    exact: true,
    wrappers: [
      // Wrappers.LogPath,
      // Wrappers.RedirectDomain,
      // Wrappers.CollectReferrer,
      // Wrappers.OnlyAuth,
      // Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal/media/create/result'],
    component: PaymentResult,
    exact: true,
    wrappers: [
      // Wrappers.LogPath,
      // Wrappers.RedirectDomain,
      // Wrappers.CollectReferrer,
      // Wrappers.OnlyAuth,
      // Wrappers.ForceUserInfo,
    ],
  },
  // 지역 기반 광고 추천
  {
    path: ['/portal/media/create/recommend'],
    component: RecommendPage,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal/media/ai-build'],
    component: AIBuild,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.OnlyAuth],
  },
  {
    path: ['/portal/media/build'],
    component: BuildPage,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.OnlyAuth],
  },
  {
    path: ['/portal/media/result'],
    component: ResultPage,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.OnlyAuth],
  },
  {
    path: ['/portal/media/voice'],
    component: AddVoice,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.AssetData,
    ],
  },
  {
    path: ['/portal/media/build/trial'],
    component: BuildTrialPage,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer],
  },
  {
    path: ['/portal/media/result/trial'],
    component: ResultTrialPage,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer],
  },
  {
    path: ['/portal/media/voice/trial'],
    component: AddVoiceTrial,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer],
  },
  {
    path: ['/portal/media/create/result'],
    component: PaymentResult,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/portal/media/create/:step'],
    component: AdCampaign,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
      Wrappers.PaymentInfo,
      Wrappers.GalleryCategoryData,
      Wrappers.StoreData,
    ],
  },
  {
    path: ['/portal/media/requested/:id'],
    component: RequestedAdCampaign,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
      Wrappers.ValidateAdCampaign,
      Wrappers.PaymentInfo,
    ],
  },
  {
    path: ['/portal/media/promotion'],
    component: Promotion,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
      Wrappers.UpdateHeaderMode,
      Wrappers.GalleryCategoryData,
      Wrappers.PromotionData,
    ],
  },
  {
    path: ['/portal/media/promotion/backoffice'],
    component: Promotion,
    exact: true,
    wrappers: [Wrappers.OnlyStage, Wrappers.GalleryCategoryData, Wrappers.PromotionData],
  },
  {
    path: ['/portal/payment'],
    component: () => {},
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.SKPayRedirectUrl],
  },
  // report
  {
    path: ['/portal/report'],
    component: Report,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
      Wrappers.UpdateHeaderMode,
      // Wrappers.AdCampaignInfo,
      // Wrappers.ValidateAdCampaignType,
    ],
  },
  {
    path: ['/portal/report/detail'],
    component: ReportDetail,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.OnlyAuth,
      Wrappers.ForceUserInfo,
      Wrappers.UpdateHeaderMode,
      // Wrappers.AdCampaignInfo,
      // Wrappers.ValidateAdCampaignType,
    ],
  },
  // community
  {
    path: ['/community'],
    component: Community,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.ForceUserInfo,
      Wrappers.GalleryCategoryData,
    ],
  },
  {
    path: ['/estimate'],
    component: Estimate,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/estimate/result'],
    component: EstimateResult,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/partner'],
    component: Partner,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  {
    path: ['/consult'],
    component: Consult,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.ForceUserInfo,
    ],
  },
  {
    path: ['/community/:id'],
    component: CommunityDetail,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.UserInfo,
      Wrappers.GalleryCategoryData,
    ],
  },
  {
    path: ['/board/:boardId'],
    component: CommunityBoard,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  {
    path: ['/board/:boardId/edit'],
    component: EditPage,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
    ],
  },
  {
    path: ['/board/:boardId/post/:id/comment'],
    component: CommentPage,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
    ],
  },
  {
    path: ['/board/:boardId/post/:id'],
    component: PostPage,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
      Wrappers.PostData,
    ],
  },
  // our-services
  {
    path: ['/services'],
    component: OurServices,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  {
    path: ['/cable-tv'],
    component: CableTV,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  // authentication..
  {
    path: ['/sign-out', '/logout'],
    component: home,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      'AUTHENTICATED',
      'REDIRECT_PREVIOUS',
    ],
  },
  {
    path: ['/sign-in', '/login'],
    component: SignIn,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAnony,
    ],
  },
  {
    path: ['/sign-up', '/register', '/sign-up/step/1'],
    component: SignUpMethod,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAnony,
      Wrappers.ResetSignUpMode,
    ],
  },
  {
    path: ['/sign-up/step/2'],
    component: SignUpType,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAnony,
      Wrappers.CheckSignUpMode,
    ],
  },
  {
    path: ['/sign-up/step/3'],
    component: SignUpForm,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAnony,
      Wrappers.CheckSignUpMode,
    ],
  },
  {
    path: ['/sign-up/social'],
    component: SignUpFormSocial,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAnony,
      Wrappers.CheckSignUpMode,
    ],
  },
  {
    path: ['/user/register'],
    component: RegisterForm,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.CheckRegisterMode,
    ],
  },
  {
    path: ['/sign-up/step/3/identity-check', '/auth/identity-check', '/auth/kakao', '/auth/naver', '/auth/google'],
    component: () => {},
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.RedirectUrl],
  },
  {
    path: ['/recover-id'],
    component: RecoverID,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAnony,
    ],
  },
  {
    path: ['/recover-password'],
    component: RecoverPwd,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAnony,
    ],
  },
  {
    path: ['/sign-up/result'],
    component: SignUpResult,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAnony,
    ],
  },
  /**
   * Franchise Pages
   */
  {
    path: ['/franchise'],
    component: FranchisePage,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.MyFranchiseAdsList,
    ],
  },
  {
    path: ['/franchise/notice'],
    component: FranchiseNotice,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.FranchiseNoticeDataList,
    ],
  },
  {
    path: ['/franchise/notice/write'],
    component: FranchiseNoticeWrite,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
    ],
  },
  {
    path: ['/franchise/notice/:id'],
    component: FranchiseNoticeDetail,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.FranchiseNoticePage,
    ],
  },
  {
    path: ['/franchise/notice/:id/edit'],
    component: FranchiseNoticeEdit,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.FranchiseNoticePage,
    ],
  },
  {
    path: ['/franchise/notice/:id/ads'],
    component: FranchiseNoticeAds,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
      Wrappers.OnlyAuth,
      Wrappers.FranchiseNoticePage,
    ],
  },
  // customer service..
  {
    path: ['/notice'],
    component: Notices,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.NoticeDataList,
    ],
  },
  {
    path: ['/notice/:id'],
    component: NoticePage,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.NoticeInfo,
    ],
  },
  {
    path: ['/trial'],
    component: Trial,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  {
    path: ['/faq'],
    component: FAQs,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.FAQDataList,
    ],
  },
  {
    path: ['/ad-media'],
    component: AdMediaPage,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
    ],
  },
  // home..
  {
    path: ['/', '/home'],
    component: home,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
      Wrappers.LandingPageInfo,
    ],
  },
  {
    path: ['/success-stories'],
    component: SuccessStories,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  {
    path: ['/success-stories/:id'],
    component: SuccessStoryPage,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.SuccessStoryData,
    ],
  },
  /**
   * Event
   */
  {
    path: ['/event'],
    component: EventPage,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UpdateHeaderMode,
      Wrappers.EventListData,
    ],
  },
  {
    path: ['/event/expo'],
    component: ExpoPage,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer],
  },
  {
    path: ['/event/thedream'],
    component: TheDreamPage,
    exact: true,
    wrappers: [
      Wrappers.LogPath,
      Wrappers.RedirectDomain,
      Wrappers.CollectReferrer,
      Wrappers.UserInfo,
      Wrappers.UpdateHeaderMode,
    ],
  },
  // tutorials
  {
    path: ['/tutorial/asset'],
    component: Tutorial,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  {
    path: ['/tutorial/asset/:id'],
    component: TutorialDetail,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  {
    path: ['/tutorial/ad'],
    component: Tutorial,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  {
    path: ['/tutorial/ad/:id'],
    component: TutorialDetail,
    exact: true,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  // loading..
  {
    path: ['/loading'],
    component: LoadingLayout,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
  // errors..
  {
    path: ['/'],
    component: Page404,
    wrappers: [Wrappers.LogPath, Wrappers.RedirectDomain, Wrappers.CollectReferrer, Wrappers.UpdateHeaderMode],
  },
]

console.log(Routes.length)

const RouterWrapper = (wrappers, Next) => {
  if ((wrappers || []).length < 1) return Next
  const Wrapper = wrappers.slice(-1).pop()
  const WrappedChild = (props) => Wrapper(props, Next)
  return RouterWrapper(wrappers.slice(0, -1), WrappedChild)
}

const RouteHolder = (route) => {
  const Wrapper = RouterWrapper(route.wrappers, route.component)
  return (
    <Route path={route.path} exact={route.exact} render={(props) => <Wrapper {...props} />} />
    // routes={route.routes}
  )
}

const ScrollToTop = ({ children }) => {
  const location = useLocation()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return children
}

const RouterContainer = ({ routes }) => (
  <ScrollToTop>
    <Switch>
      {routes.map((route, index) => (
        <RouteHolder key={index} {...route} />
      ))}
    </Switch>
  </ScrollToTop>
)

export default RouterContainer
